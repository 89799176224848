define('tlc/routes/employee', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        model: function model(params) {
            var data = this.store.query('employees', { "orderBy": "id", "equalTo": params.id });
            return data;
        }
    });
});