define('tlc/adapters/application', ['exports', 'ember', 'emberfire/adapters/firebase'], function (exports, _ember, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = _ember.default.inject;
  exports.default = _firebase.default.extend({
    firebase: inject.service()
  });
});