define('tlc/router', ['exports', 'ember', 'tlc/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('mission');
    this.route('services');

    this.route('ekemp');
    this.route('payment');
    this.route('staff');
    this.route('employee', { path: "/staff/detail/:id" });
    this.route('appointments');
  });

  exports.default = Router;
});