define('tlc/models/employees', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        linkTo: _emberData.default.attr('string'),
        displayName: _emberData.default.attr('string'),
        credentials: _emberData.default.attr('string'),
        photoFilename: _emberData.default.attr('string'),
        position: _emberData.default.attr('string'),
        iconString: _emberData.default.attr('string'),
        longDescription: _emberData.default.attr('string'),
        shortDescription: _emberData.default.attr('string'),
        paymentTypes: _emberData.default.attr('string'),
        isActive: _emberData.default.attr('boolean'),
        isSuperAdmin: _emberData.default.attr('boolean')

    });
});